<!--
 * @Author: hys
 * @Date: 2022-09-05 09:04:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-18 11:47:13
 * @Description: 请填写简介
-->
<template>
  <div class="videoItem">
    <video :id="'my-video' + data.id" class="aa" controls preload="auto">
      <source :src="data.flowURI" type="application/x-mpegURL" autoplay muted />
    </video>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {
    this.getVideo()
  },
  methods: {
    getVideo() {
      const id = 'my-video' + this.data.id
      videojs(
        id,
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true,
          autoplay: true,
          muted: true
        },
        function () {
          this.play()
        }
      )
    }
  }
}
</script>
<style scoped lang="less">
.videoItem {
  width: 200px;
  height: 200px;
  border: 1px solid red;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.aa {
  width: 400px;
  height: 300px;
  margin: 0 auto;
}
</style>
