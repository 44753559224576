<template>
  <div class="loginbody">
    <div class="logindata">
      <!--标题 -->
      <div class="logintext">
        <h3 style="color: green">用户登录</h3>
      </div>
      <!-- 登录框 -->
      <div class="formdata" style="color: black; font-size: 30px; font-weight: 700">
        <el-form ref="form" :model="loginForm" :rules="rules">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="loginForm.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 按钮 -->
      <div class="butt">
        <el-button type="primary" @click="submit">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import http from "../utils/request";
export default {
  data() {
    return {
      // 校验规则
      rules: {
        username: [
          { required: "true", message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: "true", message: "请输入用户名", trigger: "blur" },
        ],
      },
      // 登陆数据
      loginForm: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //登录验证
          if (http.post("/permission/login", this.loginForm)) {
            http
              .post("/permission/getMenu", this.loginForm)
              .then((data) => {
                if (data.data.code === 200) {
                  Cookie.set("token", data.data.token);
                  Cookie.set("owner", data.data.username);
                  // 菜单组装
                  this.$store.commit("setMenu", data.data.menu);
                  // 添加路由
                  this.$store.commit("addMenu", this.$router);
                  // 跳转到首页
                  this.$router.push("/home").catch(err => { console.log(err) });
                } else {
                  // 验证失败的弹窗
                  this.$message.error(data.data.data.message);
                }
              })
              .catch(() => {
                alert("权限错误");
              });
          } else alert("账号错误，请联系管理员");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loginbody {
  width: 100%;
  height: 100%;
  min-width: 1000px;
  background-image: url("../assets/bgc1.png");
  background-size: 100% 100%;
  background-position: center center;
  overflow: auto;
  background-repeat: no-repeat;
  position: fixed;
  line-height: 100%;
  padding-top: 150px;
}

.logintext {
  margin-bottom: 30px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.logindata {
  margin-top: 160px;
  width: 400px;
  height: 300px;
  transform: translate(-50%);
  margin-left: 73%;
}

.tool {
  display: flex;
  justify-content: space-between;
  color: #606266;
}

.butt {
  margin-top: 10px;
  text-align: center;
}

.shou {
  cursor: pointer;
  color: #606266;
}
</style>
