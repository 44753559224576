<template>
  <div>
    <!-- 右上 -->
    <div class="data1_left_top1">
      <dv-border-box-11 :title=title>
        <div class="data1_left_top1_charts" style="display: flex;align-items: center;justify-content: center;">
          <video id="playVideos" controls preload="auto" width="1160">
            <source :src="playVideoUrl" type="video/webm" autoplay muted />
          </video>
        </div>
      </dv-border-box-11>
    </div>
    <!-- 右中 -->
    <div class="data1_left_top2">
      <dv-border-box-11 title="监控列表">
        <div class="data1_left_top2_content">
          <div class="data1_left_top2_content_item" v-for="item, index in tableList" :key="index">
            <img src="http://qny.hyszhny.com/web/data1/sxt.png" alt="" @click="goliveplay(item.equipmentID)" />
            <div style="font-size: 23px;color: #f8f8f8;">{{ item.equipmentID }}号监控</div>
          </div>
        </div>
      </dv-border-box-11>
    </div>
    <!-- 右下 -->
    <div class="data1_right_top2">
      <dv-border-box-11 title="数据统计">
        <div class="data1_right_top2_charts" id="green_c"></div>
      </dv-border-box-11>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import VideoItem from './VideoItem'
import http from "../../../../utils/request";
export default {
  components: { VideoItem },
  data() {
    return {
      insectData: null,
      tableList: [],
      title: "#号监控",
    }
  },
  created() {
    this.getSoilAnalyze();
  },
  methods: {
    goliveplay(equipmentID) {
      this.title = equipmentID + "号监控";
      http.get("/api/hkJK/getPlayData?equipmentID=340200000013200000" + equipmentID)
        .then((res) => {
          let vdo = document.getElementById("playVideos");
          vdo.src = res.data;
          vdo.play();
        });
    },


    initCharts() {
      var chartDom = document.getElementById('green_c');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {},
        grid: {
          top: 70,
          bottom: 50
        },
        xAxis: [
          {
            name: '路灯',
            type: 'category',
            data: ['1号路灯', '2号路灯', '3号路灯', '4号路灯', '5号路灯', '6号路灯', '7号路灯', '8号路灯', '9号路灯', '10号路灯', '11号路灯', '12号路灯', '13号路灯', '14号路灯', '15号路灯', '16号路灯', '17号路灯', '18号路灯', '19号路灯', '20号路灯', '21号路灯',]
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '数值'
          }
        ],
        series: [
          {
            name: '剩余电量',
            type: 'line',
            data: Array(21).fill(1).map(v => Math.floor(Math.random() * (220 - 80)) + 600)
          },
           {
            name: '充电量',
            type: 'line',
            data: Array(21).fill(1).map(v => Math.floor(Math.random() * (780 - 700)) + 700)
          },
          {
            name: '放电量',
            type: 'line',
            data: Array(21).fill(1).map(v => Math.floor(Math.random() * (420 - 300)) + 300)
          },{
            name: '充电时长',
            type: 'line',
            data: Array(21).fill(1).map(v => Math.floor(Math.random() * (16 - 10)) + 10)
          }
        ]
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    http
      .get("/api/hkJK/getList?owner=yx123") ///坑爹地方，花费两天
      .then((res) => {
        this.tableList = res.data;
      })
      .catch((res) => {
        console.log("err", res);
      });

    this.initCharts()
  }
}
</script>
<style scoped lang="less">
.data1_left_top1 {
  height: 749px;
  margin-bottom: 16px;
  position: relative;

  img {
    width: 100%;
  }

  .data1_left_top1_charts {
    position: absolute;
    top: 80px;
    left: 20px;
    right: 10px;
    bottom: 30px;

  }
}

.data1_left_top2 {
  height: 315px;
  margin-bottom: 16px;
  position: relative;

  img {
    width: 100%;
  }

  .data1_left_top2_content {
    position: absolute;
    top: 80px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // 隐藏滚动条
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .data1_left_top2_content_item {
      width: 15%;
      position: relative;
      height: 156px;
      padding: 0 16px;
      padding-top: 10px;

      img {
        cursor: pointer;
        margin: 0 auto;
        height: 100%;
      }

      div {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        color: #f0f2f5;
      }
    }
  }
}

.data1_right_top2 {
  height: 600px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .data1_right_top2_charts {
    position: absolute;
    top: 80px;

    bottom: 15px;
    height: 500px;
    width: 100%;
  }
}
</style>
