<template>
  <div class="data1_center_box">
    <!-- 顶部 -->
    <div class="data1_center_top1">
      <dv-decoration-9
        style="width:250px;height:250px;color: green;margin:120px 40px;font-weight: 700;font-size: 32px;">在线59台</dv-decoration-9>
      <dv-decoration-9
        style="width:250px;height:250px;color: red;margin:120px 40px;font-weight: 700;font-size: 32px;">离线0台</dv-decoration-9>
    </div>



    <!-- 中部 -->
    <div class="data1_center_top2">
      <div class="data1_center_top2_box">
        <div class="turnLine1 turnLine"></div>
        <div class="turnLine2 turnLine"></div>
        <div class="turnLine3 turnLine"></div>
      </div>
      <div class="data1_center_qiu">
        <img src="http://qny.hyszhny.com/web/data1/data1_cent_qiu.png" alt="" />
      </div>
    </div>
    <!-- 底部 -->
    <div class="data1_center_top3">
      <div class="data1_center_top3_item">
        <img src="http://qny.hyszhny.com/web/data1/data1_center_b1.png" alt="" />
        <div>数据统计</div>
      </div>
      <div class="data1_center_top3_item">
        <img src="http://qny.hyszhny.com/web/data1/data1_center_b.png" alt="" />
        <div>实时追踪</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      dataorigin: "",
      geometries: [],
      s: "29.223556",
      d: "106.530625"
    };
  },
  created() { },
  mounted() {
    this.initMap();
  },
  methods: {
  }
}
</script>
<style scoped lang="less">
.data1_center_box {
  position: relative;
  height: 89%;

  .data1_center_top1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    height: 30%;
    width: 100%;

    img {
      width: 644px;
    }

    img:first-child {
      margin-right: 64px;
    }
  }

  .data1_center_top2 {
    position: relative;
    margin: 0 auto;
    margin-top: 40px;
    width: 800px;
    height: 900px;
    background: url(http://qny.hyszhny.com/web/data1/bg2.png) no-repeat center center;
    background-size: cover;

    .data1_center_top2_box {
      position: relative;
      height: 100%;
      width: 100%;
      transform-style: preserve-3d; //旋转
      transform: rotateX(67deg) translate3d(-5px, -270px, -291px);
      opacity: 0.6; //透明度
      background: url(http://qny.hyszhny.com/web/data1/bg3.png) no-repeat center center;
      background-size: cover;
    }

    .turnLine1 {
      background: url(http://qny.hyszhny.com/web/data1/light2.png) no-repeat center center;
      background-size: cover;
      animation: provice1 5s infinite; //动画
    }

    .turnLine2 {
      background: url(http://qny.hyszhny.com/web/data1/light3.png) no-repeat center center;
      background-size: cover;
      animation: provice1 6s linear infinite;
    }

    .turnLine3 {
      background: url(http://qny.hyszhny.com/web/data1/light4.png) no-repeat center center;
      background-size: cover;
      animation: provice1 5s linear infinite;
    }

    .turnLine {
      position: absolute;
      left: 0px;
      height: 0px;
      height: 100%;
      width: 100%;
    }

    @keyframes provice1 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .data1_center_qiu {
      position: absolute;
      top: 0px;
      left: 43%;
      transform: translateX(-50%);
      width: 528px;
      height: 472px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .data1_center_top3 {
    display: flex;
    position: absolute;
    bottom: -90px;
    left: 50%;
    transform: translateX(-50%);

    .data1_center_top3_item {
      width: 350px;
      height: 126px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      div {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 26%;
        left: 40%;
      }
    }

    .data1_center_top3_item:first-child {
      margin-right: 23px;
    }
  }
}
</style>
