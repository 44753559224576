/*
 * @Author: hys
 * @Date: 2023-07-16 04:54:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-09 14:17:56
 * @Description: 请填写简介
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../Views/Main";
import daPingYX from "../Views/largeScreen/data1/daPingYX.vue";
import daPingWT from "../Views/largeScreen/data2/daPingWT.vue";
import Login from "../Views/Login.vue";
import Cookie from "js-cookie";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/home",
    children: [],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/daPingyx",
    name: "dapingyx",
    component: daPingYX,
  },
  {
    path: "/daPingwt",
    name: "dapingwt",
    component: daPingWT,
  },
];

const router = new VueRouter({
  routes,
});

// 路由守卫:全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 获取token
  const token = Cookie.get("token");

  if (!token && to.name !== "login") {
    next({ name: "login" });
  } else if (token && to.name === "login") {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
