<template>
  <div>
    <!-- 下 -->
    <div class="data1_left_top1">
      <dv-border-box-11 title="性诱虫情测报">
        <!-- <div class="data1_left_top1_charts" id="data1_left_top1"></div> -->
        <div class="data1_left_top1_charts">
          <video controls preload="auto" width="670">
            <source src="http://47.108.157.159:8089/rtp/34020000001320000009_34020000001310000001.live.mp4" type="video/webm" autoplay
              muted />
          </video>

        </div>
      </dv-border-box-11>
    </div>
    <!-- 中 -->
    <div class="data1_left_top2">
      <dv-border-box-11 title="视频监控">
        <!-- <img src="http://qny.hyszhny.com/web/data1/data1_left_top2.png" alt="" /> -->
        <div class="data1_left_top2_content">
          <div class="data1_left_top2_content_item" v-for="item, index in 15" :key="item.id">
            <img src="http://qny.hyszhny.com/web/data1/sxt.png" alt="" @click="handlerSXT(item)" />
            <!-- <div>{{ item.name }}</div> -->
            <div style="font-size: 14px;color: #f8f8f8;">{{ index + 1 }}号监控</div>
          </div>
        </div>
      </dv-border-box-11>
    </div>
    <!-- 上 -->
    <div class="data1_right_top2">
      <dv-border-box-11 title="绿色防控">
        <div class="data1_right_top2_charts" id="data1_right_top2"></div>
      </dv-border-box-11>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import VideoItem from './VideoItem'
export default {
  components: { VideoItem },
  data() {
    return {
      videoList: null,
      activeVideo: "https://qiniu-web-assets.dcloud.net.cn/unidoc/zh/2minute-demo.mp4",
      visible: false,
      insectData: null,
    }
  },
  created() {
    this.getSoilAnalyze();
  },
  mounted() {
    // this.getAllCameraList()
    // this.getInsectAnalyze()
    this.initCharts1()
  },
  methods: {
    initCharts() {
      var myChart = echarts.init(document.getElementById('data1_left_top1'))
      const option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '虫情',
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            align: 'center'
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            labelLine: {
              normal: {
                length: 14,
                length2: 90,
                lineStyle: {
                  type: 'solid'
                }
              }
            },
            label: {
              normal: {
                formatter: (params) => {
                  return '{b| ' + params.name + '}  ' + '{c|' + params.percent.toFixed(0) + '%}'
                },
                borderWidth: 0,
                borderRadius: 4,
                padding: [-100, -70],
                height: 30,
                fontSize: 10,
                align: 'center',
                color: '#fff',
                rich: {
                  b: {
                    fontSize: 10,
                    color: '#fff'
                    // padding: [0, 0, 5, 0]
                  },
                  c: {
                    fontSize: 10,
                    color: '#fff'
                  }
                }
              }
            },
            data: this.insectData
          }
        ]
      }
      myChart.setOption(option)
    },
    initCharts1() {
      var myChart = echarts.init(document.getElementById('data1_right_top2'))
      const option = {
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '2015', '2016', '2017'],
          source: [
            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
            { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
            { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
            { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };
      myChart.setOption(option)
    },
    handlerSXT(item) {
      console.log(item)
      this.activeVideo = item
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
  }
}
</script>
<style scoped lang="less">
.data1_left_top1 {
  height: 484px;
  margin-bottom: 16px;
  position: relative;

  img {
    width: 100%;
  }

  .data1_left_top1_charts {
    position: absolute;
    top: 80px;
    left: 20px;
    right: 10px;
    bottom: 30px;

  }
}

.data1_left_top2 {
  height: 580px;
  margin-bottom: 16px;
  position: relative;

  img {
    width: 100%;
  }

  .data1_left_top2_content {
    position: absolute;
    top: 80px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // 隐藏滚动条
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .data1_left_top2_content_item {
      width: 15%;
      position: relative;
      height: 156px;
      padding: 0 16px;
      padding-top: 10px;

      img {
        cursor: pointer;
        margin: 0 auto;
        height: 100%;
      }

      div {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        color: #f0f2f5;
      }
    }
  }
}

.data1_right_top2 {
  height: 600px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .data1_right_top2_charts {
    position: absolute;
    top: 20px;
    left: 30px;
    right: 30px;
    bottom: 15px;
    height: 260px;
    width: 96%;
  }
}
</style>
