<template>
  <div>
    <div class="main">
      <!-- 左s -->
      <div class="shang">
        <dv-border-box-11 title="简介">
          <div class="list">
            <p>
              油溪镇，隶属于重庆市江津区，地处江津区西北部，东望龙华镇，南靠白沙镇，西邻石门镇、永川区临江镇，北倚吴滩镇、德感街道，距江津区人民政府驻地18千米， [1]区域总面积153.27平方千米。周慎靓王五年（前316年），油溪镇境域属江州县；1961年12月，油溪公社改为油溪镇。 截至2018年末，油溪镇户籍人口为76893人。 截至2020年6月，油溪镇下辖5个社区、9个行政村， [3]镇人民政府驻明月社区建新路125号。
            </p>
          </div>
        </dv-border-box-11>
      </div>

      <!-- 左z-->
      <div class="zhong">
        <dv-border-box-11 title="杀虫灯列表">
          <div class="data1_right_top1_content">
            <div class="data1_right_top1_content_t">
              <div class="data1_right_top1_item" v-for="(item, index) in imgArr" :key="index">
                <img src="http://qny.hyszhny.com/web/data1/data1_r_bgc.png" alt="" />
                <div class="data1_right_top1_item_flot">
                  <div class="data1_right_top1_item_icon">
                    <img :src="item.imgUrl" alt="" style="height: 50px; width: 50px" />
                  </div>
                  <div class="data1_right_top1_item_number">
                    {{ item.value }}
                  </div>
                  <div class="data1_right_top1_item_text">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dv-border-box-11>
      </div>

      <div class="xia">
        <!-- 左下 -->
        <dv-border-box-11 title="电动阀实时数据">
          <dv-scroll-board :config="config" style="
              width: 1240px;
              height: 620px;
              margin: 30px auto;
              padding-top: 70px;
            " />
        </dv-border-box-11>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      stationList: null,
      soils: null,
      trend: null,

      imgArr: [
        {
          name: "1号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "2号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "3号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "4号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "5号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "6号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "7号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "8号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "9号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "10号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "11号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "12号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "13号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },{
          name: "14号杀虫灯",
          imgUrl:
            "http://qny.hyszhny.com/web/icon/2.png",
          value: "充电中...",
        },
        
      ],

      // configJN: {
      //   data: [
      //     {
      //       name: "南阳",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "周口",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "漯河",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "郑州",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "西峡",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "漯河",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "郑州",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //     {
      //       name: "西峡",
      //       value: [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23][
      //         Math.floor(
      //           Math.random() *
      //             [67, 123, 55, 98, 123, 67, 42, 13, 76, 62, 58, 23].length
      //         )
      //       ],
      //     },
      //   ],
      // },

      config: {
        header: [
          "设备名称",
          "工作状态",
          "充电电压",
          "电池电压",
          "放电电流",
          "负载电流",
          "在线状态",
        ],
        data: [
          [
          "1号电动阀",
          "充电中...",
          (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
          (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
          ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
          "0mA",
          "在线",
        ],

        [
            "2号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "3号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "4号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "5号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "6号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "7号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "8号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "9号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "10号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "11号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "12号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "13号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "14号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "15号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "16号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "17号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "18号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "19号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],  [
            "20号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],
          [
            "21号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],
          [
            "22号电动阀",
            "充电中...",
            (Math.random() * (12 - 10) + 12).toFixed(2) + "伏",
            (Math.random() * (12 - 10) + 10).toFixed(2) + "伏",
            ((Math.random() * (12 - 10) + 10)* 80).toFixed(2) + "毫安",
            "0mA",
            "在线",
          ],
      ],
        rowNum: 12, //表格行数
        headerHeight: 25,
        headerBGC: "#00baff", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [40],
        align: ["center"],
      },
    };
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>
<style scoped lang="less">
.main {
  margin-bottom: 32px;
  position: relative;
  height: 1680px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .shang {
    height: 280px;

    .list {
      padding-top: 90px;
      margin: 0 auto;
      height: 70%;
      width: 90%;
      text-align: center;

      p {
        font-size: 23px;
        color: #029698;
        padding: 0 0.3rem;
      }

      li {
        float: left;
        font-size: 0.2rem;
        color: #ffffff;
        width: 50%;
        height: 0.4rem;
        line-height: 0.4rem;
        padding: 0.2rem;
      }
    }
  }

  .zhong {
    height: 520px;
    margin-top: 20px;

    .data1_right_top1_content {
      position: absolute;
      margin: 0 auto;
      top: 50px;
      bottom: 30px;
      left: 50px;
      right: 30px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // 隐藏滚动条
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      img {
        width: 100%;
        height: 100%;
      }

      .data1_right_top1_content_t {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        min-height: 76px;
        margin: 0 auto;

        .data1_right_top1_item {
          // padding: 2%;
          width: 210px;
          height: 100px;
          padding: 5px;
          position: relative;
          font-size: 20px;
          margin: 10px;

          .data1_right_top1_item_flot {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .data1_right_top1_item_icon {
              position: absolute;
              top: 8px;
              left: 8px;
              width: 32px;
              height: 32px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .data1_right_top1_item_number {
              position: absolute;
              top: 30%;
              left: 50%;
              transform: translate(-50%, 0);

              // width: 21px;
              height: 22px;
              font-size: 20px;
              line-height: 24px;
              font-weight: bold;
              color: #02cda6;
            }

            .data1_right_top1_item_text {
              position: absolute;
              bottom: 8px;
              left: 50%;
              transform: translate(-50%, 0);

              // width: 48px;
              height: 25px;
              line-height: 20px;
              font-size: 19px;
              font-weight: 400;
              color: #ba3d3d;
            }
          }
        }

        .data1_right_top1_item:nth-child(2n) {
          margin-right: 0;
        }
      }

      .data1_right_top1_content_b {
        margin-top: 40px;
        height: 100%;
        background-color: #f8f8f8;
      }
    }
  }

  .xia {
    height: 800px;
    width: 100%;
  }
}
</style>
