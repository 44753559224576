/*
 * @Author: hys
 * @Date: 2023-07-16 04:54:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-07-11 16:28:02
 * @Description: 请填写简介
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index'
import TMap from '@map-component/vue-tmap';
import dataV from '@jiaminghi/data-view'




Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(TMap)
Vue.use(dataV)


new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    store.commit('addMenu', router)
  }
}).$mount('#app')
