<!--
 * @Author: hys
 * @Date: 2023-07-16 04:54:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-08-08 22:09:13
 * @Description: 请填写简介
-->
<template>
  <div class="tabs">
    <div>
      <el-tag
        v-for="(item, index) in tags"
        :key="item.path"
        :closable="item.name !== 'home'"
        :effect="item.name === $route.name ? 'dark' : 'plain'"
        @click="changeMenu(item)"
        @close="handleClose(item, index)"
      >
        {{ item.label }}
      </el-tag>
    </div>

    <!-- <el-button
      style="margin-right: 20px"
      plain
      size="mini"
      @click="changeToList"
      >刷新</el-button
    > -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    changeMenu(item) {
      this.$router.push({ name: item.name });
    },
    handleClose(item, index) {
      // 删除面包屑数据
      this.$store.commit("closeTag", item);
      // 如果删除的刚好是自己
      if (item.name === this.$route.name) {
        const length = this.tags.length;
        // 如果删除的是最后一个:跳到前一个
        if (length === index) {
          this.$router.push({ name: this.tags[index - 1].name });
        }
        // 不是最后一个:往后一个
        else {
          this.$router.push({ name: this.tags[index].name });
        }
      }
    },
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabList,
    }),
  },
};
</script>

<style lang="less" scoped>
.tabs {
  padding: 20px 20px 0 20px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  .el-tag {
    margin-right: 20px;
    // 鼠标悬停:小手
    cursor: pointer;
  }
}
</style>
