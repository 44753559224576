<template>
  <div>
    <!-- 上 -->
    <div class="data1_right_top2">
      <dv-border-box-11 title="绿色防控">
        <!-- <div class="data1_right_top2_charts" id="data1_right_top2"></div> -->
        <div class="data1_right_top2_charts">
          <dv-scroll-board :config="config" style="width:640px;height:330px" />
        </div>
      </dv-border-box-11>
    </div>
    <!-- 中 -->
    <div class="data1_left_top2">
      <dv-border-box-11 title="智慧路灯">
        <!-- <img src="http://qny.hyszhny.com/web/data1/data1_left_top2.png" alt="" /> -->
        <div class="data1_left_top2_content">
          <div class="data1_left_top2_content_item" v-for="item, index in 63" :key="item.id">
            <img
              src="http://qny.hyszhny.com/web/%E5%AE%98%E7%BD%91%E5%9B%BEjpg/%E8%B7%AF%E7%81%AF/%E8%B7%AF%E7%81%AF1.jpg"
              alt="" @click="handlerSXT(item)" />
            <!-- <div>{{ item.name }}</div> -->
            <div style="font-size: 14px;color: #f8f8f8;">{{ index + 1 }}号路灯</div>
          </div>
        </div>
      </dv-border-box-11>
    </div>

    <!-- 下 -->
    <div class="data1_left_top1">
      <dv-border-box-11 title="灯诱虫情测报">
        <!-- <div class="data1_left_top1_charts" id="data1_left_top1"></div> -->
        <div class="data1_left_top1_charts">
          <el-carousel :interval="4000" type="card" height="200px">
            <el-carousel-item v-for="item in 6" :key="item">
              <img src="http://qny.hyszhny.com/yoloSourceImg/imei861714053823146/test.jpg" height="200" width="240"
                alt="" />
            </el-carousel-item>
          </el-carousel>

        </div>
      </dv-border-box-11>
    </div>
    <el-dialog :visible.sync="visible" title="视频监控" @close="cancel" :footer="null">
      <!-- <video-item :data="activeVideo" v-if="visible"></video-item> -->
      <DHPlayer ref="dhplayer" video-id="dhplayer" :window-type="0" @createSuccess="createSuccess" />
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import VideoItem from './VideoItem'
export default {
  components: { VideoItem },
  data() {
    return {
      videoList: null,
      activeVideo: "https://www.cloud-dahua.com/h5/#/h5Live?companyId=1041509&channelId=1018166719014744064&timeD=1720680372173",
      visible: false,
      insectData: null,

      config: {
        header: ["组织名称", "组织编号", "组织联系方式"],
        data: [
          ["张三慈善会", "2233111", "13797646522"],
          ["李四互助会", "2233112", "13766258223"],
          ["王五慈善会", "2233113", "13781519821"],
          ["飞蝗慈善会", "2255156", "18915123112"],
          ["飞度", "2255623", "18911231212"],
          ["雷诺机构", "2233111", "18632456985"],
          ["并艾车友会", "1155631", "18123121232"],
          ["成群网络会", "1100352", "18121212301"],
          ["毒友交流会", "1252120", "16896656562"],
          ["Scaner交流会", "1212512", "15366568456"],
        ],
        rowNum: 3, //表格行数
        headerHeight: 30,
        headerBGC: "#00baff", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [40],
        align: ["center"],
      },
    }
  },
  created() {
    this.getSoilAnalyze()
  },
  mounted() {
    this.getAllCameraList()
    this.getInsectAnalyze()
  },
  methods: {
    initCharts() {
      var myChart = echarts.init(document.getElementById('data1_left_top1'))
      const option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '虫情',
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            align: 'center'
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            labelLine: {
              normal: {
                length: 14,
                length2: 90,
                lineStyle: {
                  type: 'solid'
                }
              }
            },
            label: {
              normal: {
                formatter: (params) => {
                  return '{b| ' + params.name + '}  ' + '{c|' + params.percent.toFixed(0) + '%}'
                },
                borderWidth: 0,
                borderRadius: 4,
                padding: [-100, -70],
                height: 30,
                fontSize: 10,
                align: 'center',
                color: '#fff',
                rich: {
                  b: {
                    fontSize: 10,
                    color: '#fff'
                    // padding: [0, 0, 5, 0]
                  },
                  c: {
                    fontSize: 10,
                    color: '#fff'
                  }
                }
              }
            },
            data: this.insectData
          }
        ]
      }
      myChart.setOption(option)
    },
    initCharts1() {
      const maxValue = [100, 100]
      const value = this.soils.map((i) => i.value)
      const enlargeValue = this.soils.map((i) => {
        if (i.max !== 100) {
          return (100 / i.max) * i.value
        } else {
          return i.value
        }
      })
      const errorValue = this.soils.map((i) => [i.base - i.max * 0.125, i.base + i.max * 0.125])
      const unit = this.soils.map((i) => i.unit)

      var myChart = echarts.init(document.getElementById('data1_right_top2'))
      const option = {
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: ['温度', '湿度']
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12'
              },
              formatter: function (value, index) {
                return value + unit[index]
              }
            },
            data: value
          }
        ],
        series: [
          {
            name: '量度',
            type: 'bar',
            zlevel: 1,
            barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: function (params) {
                  if (
                    value[params.dataIndex] > errorValue[params.dataIndex][0] &&
                    value[params.dataIndex] < errorValue[params.dataIndex][1]
                  ) {
                    return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: '#fff'
                      },
                      {
                        offset: 1,
                        color: '#87C2FE'
                      }
                    ])
                  } else {
                    return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: '#ffa2a2'
                      },
                      {
                        offset: 1,
                        color: '#ff4e4e'
                      }
                    ])
                  }
                }
              }
            },
            data: [
              {
                value: enlargeValue[0]
              },
              {
                value: enlargeValue[1]
              }
            ]
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 14,
            barGap: '-100%',
            itemStyle: {
              normal: {
                color: 'rgba(43,160,255,0.2)',
                barBorderRadius: 30
              }
            },
            data: [
              {
                value: maxValue[0]
              },
              {
                value: maxValue[1]
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
    },
    handlerSXT(item) {
      console.log(item)
      this.activeVideo = item
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    createSuccess() {
      this.$refs.dhplayer.setRealTime({
        // channelId: this.channelId,//后端给通道号
        stdRtsp: true, // 默认false
        path: "https://www.cloud-dahua.com/h5/#/h5Live?companyId=1041509&channelId=1018166719014744064&timeD=1720680372173"
        // path: `${this.playerUrl}?token=${this.playerToken}`,//后端给的地址，看你们咋拼接，需不需要token
      });
    }
  }
}
</script>
<style scoped lang="less">
.data1_left_top1 {
  height: 500px;
  margin-bottom: 16px;
  position: relative;
  width: 700px;

  img {
    width: 90%;
  }

  .data1_left_top1_charts {
    position: absolute;
    top: 80px;
    left: 30px;
    right: 30px;
    bottom: 30px;
  }
}

.data1_left_top2 {
  height: 728px;
  margin-bottom: 16px;
  position: relative;
  width: 700px;

  img {
    width: 100%;
  }

  .data1_left_top2_content {
    position: absolute;
    top: 80px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    // 隐藏滚动条
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .data1_left_top2_content_item {
      width: 15%;
      position: relative;
      height: 96px;
      padding: 0 16px;
      padding-top: 10px;

      img {
        cursor: pointer;
        margin: 20px 0px 10px;
        // height: 100%;
        height: 60px;
        box-shadow: 0 0 10px rgb(0, 153, 184) inset, 0 0 30px rgb(0, 153, 184);
      }

      div {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;

        font-size: 17px;
        font-weight: 400;
        color: #f0f2f5;
      }
    }
  }
}

.data1_right_top2 {
  height: 420px;
  position: relative;
  width: 700px;

  img {
    width: 100%;
    height: 100%;
  }

  .data1_right_top2_charts {
    position: absolute;
    // top: 80px;
    // left: 30px;
    // right: 30px;
    // bottom: 30px;
    top: 60px;
    left: 30px;
    right: 30px;
    bottom: 15px;
  }
}
</style>
