<!--
 * @Author: hys
 * @Date: 2024-06-18 10:43:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-18 23:11:43
 * @Description: 请填写简介
-->
<template>
  <div id="aaa" class="screen_content">
    <slot></slot>
  </div>
</template>
<script>
function setScale() {
  const designWidth = 3840
  const designHeight = 2160
  const scale =
    document.documentElement.clientWidth / document.documentElement.clientHeight < designWidth / designHeight
      ? document.documentElement.clientWidth / designWidth
      : document.documentElement.clientHeight / designHeight
  document.querySelector('#aaa').style.transform = `scale(${scale}) translate(-50%)`
}
const fun = fangdou(setScale)
window.onresize = function () {
  fun()
}
function fangdou(fun) {
  let timer
  let aa = false
  function bbb() {
    if (!aa) {
      aa = true
    } else {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fun()
      aa = false
    }, 500)
  }
  return bbb
}

export default {
  data() {
    return {}
  },
  created() {},
  mounted() {
    setScale()
  },
  methods: {}
}
</script>
<style scoped lang="less">
.screen_content {
  display: inline-block;
  width: 3840px;
  height:2160px;
  background: rgba(255, 255, 255, 0.05);
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
}
</style>
