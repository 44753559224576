<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
}
</script>

<style>
html,
body,
h3,
p {
  margin: 0;
  padding: 0
}
</style>