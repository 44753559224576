<template>
  <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse"
    background-color="#333633" text-color="#fff" active-text-color="#ffd04b">
    <div class="menu-tab">
      <div style="margin-top: 5px; display: flex" v-if="!isCollapse">
        <img style="
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 14px;
            margin-left: 10px;
            margin-top: 6px;
          " src="../assets/images/logo.png" alt="" />
        <h3 style="
            text-align: center;
            line-height: 48px;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
          ">
          {{ "禾缘生云平台" }}
        </h3>
      </div>
      <div style="margin-top: 5px; display: flex" v-if="isCollapse">
        <img style="
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 14px;
            margin-left: 12px;
            margin-top: 8px;
          " src="../assets/images/logo.png" alt="" />
      </div>
    </div>

    <el-submenu v-for="subItem in mainMenus" :key="subItem.name">
      <template slot="title">
        <div>
          <i :class="`el-icon-${subItem.icon}`"></i>
          <span>{{ subItem.label }}</span>
        </div>
      </template>

      <!-- <template slot="title">
        <div>
          <i :class="`el-icon-${subItem.icon}`"></i>
          <span  @click="clickDaPing()">大屏入口</span>
        </div>
      </template> -->

      <el-menu-item-group v-if="subItem.label == '数字农场'">
        <el-menu-item v-for="Item in farmMenus" :key="Item.name" @click="clickItem(Item)">
          <i :class="`el-icon-${Item.icon}`"></i>
          <span slot="title">{{ Item.label }}</span>
        </el-menu-item>
      </el-menu-item-group>

      <el-menu-item-group v-if="subItem.label == '智能设备'">
        <el-menu-item v-for="Item in deviceMenus" :key="Item.name" @click="clickItem(Item)">
          <i :class="`el-icon-${Item.icon}`"></i>
          <span slot="title">{{ Item.label }}</span>
        </el-menu-item>
      </el-menu-item-group>



    </el-submenu>
    <div style="text-align: center;padding: 26px;position: fixed;margin-top: 580px;margin-left: 20px;">
      <!-- <el-button
        plain
        type="primary"
        @click="clickDaPing()"
        style="
          color: #113420;
          background-color: #daede2;
          font-size: 14px;
          font-weight: 400;
        "
        >大屏入口</el-button
      > -->
    </div>
  </el-menu>
</template>
<script>
import cookie from "js-cookie";
export default {
  data() {
    return {
      mainMenus: [
        {
          icon: "s-home",
          label: "首页",
          name: "home",
        },
        {
          icon: "s-grid",
          label: "智能设备",
          name: "device",
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    clickItem(item) {
      // 防止自己跳自己的报错
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
      // 面包屑
      this.$store.commit("SelectMenu", item);
    },
    clickDaPing() {
      this.$router.replace("/daping");
    },
  },
  computed: {
    MenuData() {
      return JSON.parse(cookie.get("menu")) || this.$store.state.tab.menu;
    },
    farmMenus() {
      return this.MenuData.filter((item) => item.fatherLabel == "NC");
    },
    deviceMenus() {
      return this.MenuData.filter((item) => item.fatherLabel == "ZN");
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  height: 100vh;
  // Aside和Header之间没有边框缝隙
  border-right: none;
  background-color: rgba(51, 54, 51, 1);
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  height: 100%;
  padding-left: 24px;
  .menu-tab {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 38px;
    background-color: rgba(51, 54, 51, 1);
  }
}
</style>
