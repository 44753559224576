<template>
  <div>
    <div class="data1_right_top1">
      <dv-border-box-11 title="环境监测">
        <div class="data1_right_top1_content">
          <div class="data1_right_top1_content_t">
            <div class="data1_right_top1_item" v-for="(item, index) in imgArr" :key="index">
              <img src="http://qny.hyszhny.com/web/data1/data1_r_bgc.png" alt="" />
              <div class="data1_right_top1_item_flot">
                <div class="data1_right_top1_item_icon">
                  <img :src="item.imgUrl" alt="" />
                </div>
                <div class="data1_right_top1_item_number">
                  {{ item.value }}
                  <!-- <span style="'fontSize':12px ">{{ item.unit }}</span> -->
                </div>
                <div class="data1_right_top1_item_text">{{ item.name }}</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-11>
      <!-- 统计表 -->
      <dv-border-box-11 title="数据统计">
        <!-- <div class="data1_right_top1_content_b" id="data1_right_top1_content_b"></div> -->
        <dv-capsule-chart :config="configJN" style="width:450px;height:400px;padding:110px 40px 20px;" />
      </dv-border-box-11>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      stationList: null,
      soils: null,
      trend: null,
      configJN: {
        data: [
          {
            name: '南阳',
            value: 167
          },
          {
            name: '周口',
            value: 67
          },
          {
            name: '漯河',
            value: 123
          },
          {
            name: '郑州',
            value: 55
          },
          {
            name: '西峡',
            value: 98
          }
        ]
      },
      imgArr: [
        { name: '大气温度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon1.png', value: '25度' },
        { name: '大气湿度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon2.png', value: '25度' },
        { name: '照度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon3.png', value: '25度' },
        { name: '日照时数', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon4.png', value: '25度' },
        { name: '风向', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon5.png', value: '25度' },
        { name: '风速', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon6.png', value: '25度' },
        { name: '雨量', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon7.png', value: '25度' },
        { name: 'pH值', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon8.png', value: '25度' }
      ]
    }
  },
  created() {
  },
  mounted() { },
  methods: {
    initCharts2() {
      var myChart = echarts.init(document.getElementById('data1_right_top1_content_b'))
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        legend: {
          icon: 'rect',
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: this.trend.legendData,
          top: '10',
          right: '10%',
          textStyle: {
            fontSize: 12,
            color: '#F1F1F3'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#57617B'
              }
            },
            data: this.trend.axisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#57617B'
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14
              }
            },
            splitLine: {
              lineStyle: {
                color: '#57617B'
              }
            }
          }
        ],
        series: [
          {
            name: this.trend.seriesData[0].name,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(137, 189, 27, 0.5)'
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(137, 189, 27, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(137,189,27)',
                borderColor: 'rgba(137,189,2,0.27)',
                borderWidth: 12
              }
            },
            data: this.trend.seriesData[0].data
          },
          {
            name: this.trend.seriesData[1].name,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0, 136, 212, 0.5)'
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(0, 136, 212, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(0,136,212)',
                borderColor: 'rgba(0,136,212,0.2)',
                borderWidth: 12
              }
            },
            data: this.trend.seriesData[1].data
          },
          {
            name: this.trend.seriesData[2].name,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 2
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(219, 50, 51, 0.5)'
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(219, 50, 51, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(219,50,51)',
                borderColor: 'rgba(219,50,51,0.2)',
                borderWidth: 12
              }
            },
            data: this.trend.seriesData[2].data
          }
        ]
      }
      myChart.setOption(option)
    },
    // getWeather() {
    //   getWeather().then((res) => {
    //     constimgArr : [
    //       { name: '大气温度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon1.png' },
    //       { name: '大气湿度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon2.png'},
    //       { name: '照度', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon3.png'},
    //       { name: '日照时数', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon4.png' },
    //       { name: '风向', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon5.png' },
    //       { name: '风速', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon6.png' },
    //       { name: '雨量', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon7.png' },
    //       { name: 'pH值', imgUrl: 'http://qny.hyszhny.com/web/data1/data1_icon8.png' }
    //     ]
    //     this.stationList = res.station
    //       .filter((item) => {
    //         return ['大气温度', '大气湿度', '照度', '日照时数', '风向', '风速', '雨量', 'pH值'].includes(item.name)
    //       })
    //       .map((i) => {
    //         imgArr.forEach((r) => {
    //           if (r.name === i.name) {
    //             i.imgUrl = r.imgUrl
    //           }
    //         })
    //         return i
    //       })
    //     this.trend = res.trend
    //     this.$nextTick(() => {
    //       this.initCharts2()
    //     })
    //   })
    // }
  }
}
</script>
<style scoped lang="less">
.data1_right_top1 {
  // height: 512px;
  margin-bottom: 32px;
  position: relative;
  height: 832px;
  width: 700px;
  img {
    width: 100%;
    height: 100%;
  }

  .data1_right_top1_content {
    position: absolute;
    top: 80px;
    bottom: 30px;
    left: 50px;
    right: 30px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    .data1_right_top1_content_t {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 0;
      min-height: 76px;
      margin: 0 auto;

      .data1_right_top1_item {
        // padding: 2%;
        width: 30%;
        height: 82px;
        padding: 5px;
        position: relative;

        .data1_right_top1_item_flot {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .data1_right_top1_item_icon {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .data1_right_top1_item_number {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, 0);

            // width: 21px;
            height: 22px;
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
            color: #02cda6;
          }

          .data1_right_top1_item_text {
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translate(-50%, 0);

            // width: 48px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #f8f8f8;
          }
        }
      }

      .data1_right_top1_item:nth-child(2n) {
        margin-right: 0;
      }
    }

    .data1_right_top1_content_b {
      margin-top: 40px;
      height: 100%;
      background-color: #f8f8f8;
    }
  }
}
</style>
