import { render, staticRenderFns } from "./ScreenBox.vue?vue&type=template&id=030e5992&scoped=true"
import script from "./ScreenBox.vue?vue&type=script&lang=js"
export * from "./ScreenBox.vue?vue&type=script&lang=js"
import style0 from "./ScreenBox.vue?vue&type=style&index=0&id=030e5992&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030e5992",
  null
  
)

export default component.exports