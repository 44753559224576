<template>
  <div class="data1">
    <Screen-box>
      <div class="data1_content">
        <div class="data1_title"></div>
        <dv-decoration-5 dur="2" style="width:100vw;height:20px;margin: -30px auto 30px;" />
        <div class="data1_time">当前时间：{{ nowTime }}</div>
        <div class="data1_mainView">    
          <div class="data1_left">
            <data1-right></data1-right>
          </div>
          <div class="data1_center">
            <data1-center></data1-center>
          </div>
          <div class="data1_right">
            <data1-left1></data1-left1>
          </div>
        </div>
      </div>
    </Screen-box>
  </div>
</template>

<script>
import VideoItem from './component/VideoItem'
import ScreenBox from '../component/ScreenBox'
import Data1Left from './component/Data1Left'
import Data1Right from './component/Data1Right'
import Data1Center from './component/Data1Center'
import Data1Left1 from './component/Data1Left1'
import Data1Right1 from './component/Data1Right1'
function timestampToTime(timestamp) {
  // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var date = new Date(timestamp)
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

export default {
  components: { VideoItem, ScreenBox, Data1Left, Data1Right,Data1Left1, Data1Right1, Data1Center },
  data() {
    return {
      nowTime: ''
    }
  },
  created() {
    this.nowTime = timestampToTime(new Date())
    setInterval(() => {
      this.nowTime = timestampToTime(new Date())
    }, 1000)
  },
  mounted() { },
  methods: {}
}
</script>
<style scoped lang="less">
.data1 {
  background-image: url("http://qny.hyszhny.com/web/data1/data1_bgc.png");
  // background: linear-gradient(rgba(227, 223, 223, 0.7), rgba(106, 106, 129, 0.7)), url('http://qny.hyszhny.com/web/data1/background-ws.jpg') no-repeat 0% 20%/ cover;
  width: 100vw;
  height: 100vh;
}

.data1_content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .data1_title {
    margin: 20px  66px 30px;
    height: 220px;
    background: url(http://qny.hyszhny.com/web/data1/data1_title.png);
    background-size: 100% auto;
    flex-shrink: 0;
  }

  .data1_time {
    text-align: end;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #05c6a2;
    margin-right: 150px;
  }

  .data1_mainView {
    flex-grow: 2;
    display: flex;
    justify-content:space-evenly;
    margin: 24px 56px 52px 56px;
    .data1_left {
      width: 1300px;
      flex-shrink: 0;
    }

    .data1_center {
      flex-grow: 1;
      height: 100%;
    }

    .data1_right {
      width: 1300px;
      flex-shrink: 0;
    }
  }
}
</style>
