<!--
 * @Author: hys
 * @Date: 2023-07-16 04:54:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-06 10:12:00
 * @Description: 请填写简介
-->
<template>
  <el-container>
    <!-- width自适应,不然header与aside有间隔 -->
    <el-aside width="auto">
      <common-aside />
    </el-aside>
    <el-container>
      <el-header>
        <common-header />
      </el-header>
      <common-tags />

      <el-main class="elmain">
        <router-view></router-view>
      </el-main>
      
    </el-container>
  </el-container>
</template>

<script>
import CommonAside from "../components/CommonAside.vue";
import CommonHeader from "../components/CommonHeader.vue";
import CommonTags from "../components/CommonTags.vue";
export default {
  data() {
    return {};
  },
  components: {
    CommonAside,
    CommonHeader,
    CommonTags,
  },
};
</script>

<style lang="less" scoped>
.elmain {
  background-color:#F8F8F8;
  padding: 30px;
  height: 100vh;
  overflow-y: hidden; 
}
.el-header {
  padding: 0;
}
</style>
